<template>
  <div id="bd">
    <div id="ban-in">
      <div class="headerbox">
        <div class="bannerbox">
          <h2 class="fadeInRight animated">组织架构</h2>
          <div class="words fadeInUp animated">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;江西省云智大数据产业研究院批复成立于2020年6月，是省内定位于科研成果的产业化，重点面向工业互联网领域开展大数据应用研究工作的科研机构。业务主管单位为江西省工业和信息化厅，属于中国电子工业标准化技术协会委员单位、江西省青年科技工作者协会副会长单位及江西省特色领域安全产品工控安全、数据安全服务单位。
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;江西省云智大数据产业研究院按照省委省政府数字经济“一号发展工程”和关于推进大数据产业发展的决策部署，以突破大数据产业关键核心技术，研发数字经济底层平台，构建大数据产业创新生态，建设大数据产业高端智库，打造大数据产业人才高地为职责使命，面向全省102个省级以上开发区，服务6万余家工业企业，积极创新研究大数据技术，全方位助力我省工业互联网大数据科研成果转化落地。
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;省本级研究院设有5大研究中心、2个办事机构及1个5G+新经济展示馆
          </div>
        </div>
      </div>
      <!--        <div class="ban-bg"></div>-->
    </div>

    <!-- 组织架构 -->
    <div class="card-1">
      <div class="wp">
        <div class="title">
          <h3>5⼤研究中⼼、2个办事机构及1个5G+新经济展⽰馆</h3>
        </div>

        <div class="cont">
          <img src="./images/1.png" alt="" />
          <img src="./images/2.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 专家团队 -->
    <div class="card-1">
      <div class="wp">
        <div class="title">
          <h3>专家团队</h3>
        </div>

        <div class="cont cont1">
          <div class="title">
            <span>院长</span>
            <ul>
              <li>
                <img src="./images/3.png" alt="" />
                <p>童伙球</p>
              </li>
            </ul>
          </div>

          <div class="title">
            <span>副院长</span>
            <ul>
              <li>
                <img src="./images/4.png" alt="" />
                <p>罗良清</p>
              </li>
              <li>
                <img src="./images/5.png" alt="" />
                <p>⽯&nbsp;磊</p>
              </li>
            </ul>
          </div>

          <div class="title">
            <span>副理事长</span>
            <ul>
              <li>
                <img src="./images/6.png" alt="" />
                <p>沈蔚锋</p>
              </li>
            </ul>
          </div>

          <div class="title">
            <span>专家委员会<br />主任、副主任</span>
            <ul>
              <li>
                <img src="./images/7.png" alt="" />
                <p>刘且根</p>
              </li>
              <li>
                <img src="./images/8.png" alt="" />
                <p>杨&nbsp;凡</p>
              </li>
            </ul>
          </div>

          <!-- <div class="title">
            <span>秘书处</span>
            <ul>
              <li></li>
            </ul>
          </div>

          <div class="title">
            <span>业务部门</span>
            <ul>
              <li></li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 主要职能 -->
    <div class="projectPosition">
      <div class="wp">
        <div class="title">
          <h3>主要职能</h3>
          <h5>研究院积极构建“四⼤⽣态圈”</h5>
        </div>
        <div class="projectPosition-box">
          <div class="projectPosition-box-item">
            <div class="projectPosition-box-card">
              <img
                src="../../../assets/front/product/pp1.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>数字经济</p>
                <h2>
                  围绕园区企业数字化转型、产业链现代化建设，<span>开展关键技术攻关、项⽬申报、战略咨询、成果应⽤和核⼼系统研发等服务</span>，实现技术赋能，加速产业创新成果落地，助⼒数字经济产业与实体经济融合发展，<span
                    >构建数字经济圈。</span
                  >
                </h2>
              </div>
            </div>
            <div class="projectPosition-box-card">
              <img
                src="../../../assets/front/product/pp2.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>⽣态资源</p>

                <h2>
                  聚焦“双碳”⽬标，构建新型电⼒系统，整合国家、省级⼤院⼤所及国内顶尖⼚商资源，<span>提供⻛电、光伏、储能及能源管控平台建设等⼀站式双碳解决⽅案</span>，实施技术应⽤服务，实现“绿⾊园区”“绿⾊⼯⼚”“零碳校园”建设，<span
                    >构建⽣态资源圈。</span
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="projectPosition-box-item">
            <div class="projectPosition-box-card">
              <img
                src="../../../assets/front/product/pp3.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>⾦融科技</p>
                <h2>
                  ⽴⾜“建圈强链”，协同全国全域⾦融机构，<span>持续发布普惠性⾦融科技产品，探索银企“共同成⻓”战略合作</span>，拉动⾦融资源向科技创新领域汇集，解决企业创新⻔槛⾼、融资难问题，形成“科技—产业—⾦融”良性循环，<span
                    >构建⾦融科技圈。</span
                  >
                </h2>
              </div>
            </div>
            <div class="projectPosition-box-card">
              <img
                src="../../../assets/front/product/pp4.png"
                width="50"
                height="50"
              />
              <div class="projectPosition-box-card-content">
                <p>产业创新</p>
                <h2>
                  根据发展“新质⽣产⼒”要求，<span>提供政策⽀撑、产业规划、技术研究编制等</span>，探索“三新”（新产品、新技术、新模式）场景应⽤，联通⾼校、科研机构、企业等单位，通过<span>⼈才交流、合作攻关、共建平台</span>等⽅式，引进转化⼀批重⼤成果，<span>构建产业创新圈</span>。
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="card-1" style="margin-bottom: 100px">
      <div class="wp">
        <div class="title">
          <h3>合作伙伴</h3>
        </div>
        <div class="cont">
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;研究院的主要合作单位有：中国信息通信研究院、中国赛宝实验室（⼯业和信息化部电⼦第五研究所）、赛迪研究院(中国电⼦信息产业发展研究院)、国家⼯业信息安全发展研究中⼼、⼯业和信息化部服务型制造研究院、中国电⼦技术标准化研究院、上海产业技术研究院、中国移动江西5G产业研究院、中国电信（江西）⼯业互联⽹研究院、中国联通（江西）⼯业互联⽹研究院、江西省⾦控投资集团有限公司、⾦⻛科技股份有限公司、三六零⽹络安全技术研究院。
          </h3>
          <img style="width: 100%" src="./images/9.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'yjyAbout',
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.cooperativeClient-right',
          prevEl: '.cooperativeClient-left',
        },
      },
      tab1: 1,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#bd {
  //padding-top: 90px;
}

.cooperativeClient .client-list {
  padding: 0 0px 20px;
}
.cooperativeClient .client-list li {
  position: relative;
  float: left;
  width: 220px;
  height: 120px;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 17.5px;
  transition: all 0.2s;
  margin-bottom: 25px;
}
.cooperativeClient .client-list li:nth-of-type(5n) {
  margin-right: 0;
}
.cooperativeClient .client-list li a {
  display: block;
  line-height: 120px;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #ffffff;
}
.cooperativeClient .client-list li a img {
  display: inline-block;
  width: 140px;
  height: 60px;
  vertical-align: middle;
}
.cooperativeClient .client-list li:hover {
  //box-shadow: 0px 2px 6px 0px rgba(58, 151, 255, 0.34);
}

.cooperativeClient .client-list li i {
  display: block;
  position: absolute;
  overflow: hidden;
  background-image: url(../../../assets/front/home/border.svg);
  background-repeat: no-repeat;
  transition: all 0.5s;
  width: 0;
  height: 0;
  opacity: 0;
}

.cooperativeClient .client-list li i.t {
  width: 0;
  height: 0;
  overflow: hidden;
  background-position: 0 top;
  background-size: 100%;
  top: 0;
  left: 0;
}

.cooperativeClient .client-list li:hover i.t {
  width: 100%;
  height: 3px;
}

.cooperativeClient .client-list li i.r {
  width: 0;
  height: 0;
  background-position: right 0;
  background-size: auto 100%;
  right: 0;
  top: 0;
}

.cooperativeClient .client-list li:hover i.r {
  height: 100%;
  width: 3px;
}

.cooperativeClient .client-list li i.b {
  width: 0;
  height: 0;
  background-position: 0 bottom;
  background-size: 100%;
  bottom: 0;
  right: 0;
}

.cooperativeClient .client-list li:hover i.b {
  width: 100%;
  height: 3px;
}

.cooperativeClient .client-list li i.l {
  width: 0;
  height: 0;
  background-position: left 0;
  background-size: auto 100%;
  left: 0;
  bottom: 0;
}

.cooperativeClient .client-list li:hover i.l {
  height: 100%;
  width: 3px;
}

.cooperativeClient .client-list li:hover i {
  opacity: 1;
}

.cooperativeClient-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .cooperativeClient-tab-item {
    cursor: pointer;
    height: 4px;
    width: 40px;
    background: #1f73ff;
    opacity: 0.2;
    margin-right: 20px;
  }
  .cooperativeClient-tab-item-active {
    opacity: 1;
  }
}
.cooperativeClient {
  background: #f6f8fb;
  padding-bottom: 60px;
}

.projectPosition {
  background: #fff;
  h5 {
    font-size: 26px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #333333;
    margin-top: 20px;
  }
  .projectPosition-box {
    display: flex;
    flex-direction: row;
    padding: 0 60px;
    padding-bottom: 60px;
    min-height: 300px;
  }
  .projectPosition-box-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .projectPosition-box-item {
    width: 50%;
  }
  .projectPosition-box-card {
    flex: 1;
    display: flex;
    img {
      display: inline-block;
      width: 50px !important;
      height: 50px !important;
    }
  }
  .projectPosition-box-card-content {
    margin-left: 20px;
    p {
      font-size: 28px;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: bolder;
      color: #333;
      margin-top: 8px;
    }
    h1 {
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    h2 {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      width: 420px;
      span {
        color: #328bff;
      }
    }
    ul {
      li {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: PingFang SC-细体, PingFang SC;
        font-weight: normal;
        color: #333333;
        font-weight: 100;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #0066db;
          border-radius: 50%;
          opacity: 1;
          margin-right: 8px;
          margin-top: 8px;
          vertical-align: middle;
        }
      }
    }
  }
}
.title {
  color: #1890ff;
  font-family: PingFang SC, PingFang SC;
  text-align: center;
  padding: 40px 0;
  line-height: 30px;
  overflow: hidden;
  font-size: 30px;
  font-weight: bold;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  padding-top: 90px;
  background-size: 100% 100%;
  height: 500px;
  background: #008cd6;
  //  url(../../../assets/front/about/aboutbanner.png) center center
  //   no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.headerbox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.bannerbox {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
.bannerbox h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: 10px;
  margin: 0;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
}
.bannerbox .words {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 16px;
  color: #fff;
  margin-top: 20px;
  letter-spacing: 3px;
  padding: 0 454px;
  padding-top: 40px;
  line-height: 30px;
  font-weight: 100;
  animation-name: fadeInOP;
  animation: fadeInOP 2s linear;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInOP {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bannerbox .words span {
  color: #fff000;
}

.card-1 {
  .cont {
    text-align: center;
    h3 {
      font-size: 18px;
      letter-spacing: 3px;
      line-height: 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      text-align: start;
      margin-bottom: 20px;
    }
    .title {
      font-size: 16px;
      color: #333;
      padding: 0;
      display: flex;
      span {
        width: 120px;
        display: inline-block;
      }
      ul {
        display: flex;
        li {
          display: flex;
          flex-direction: column;
          margin-right: 18px;
          img {
            width: 80px;
            height: 100px;
          }
          p {
            margin: 2px;
            color: #ccc;
            font-weight: unset;
            font-size: 14px;
          }
        }
      }
    }
  }
  .cont1 {
    padding: 0 80px;
  }
}
</style>
